import { isDefined } from "@clipboard-health/util-ts";
import { useWorkerGeoLocation } from "@src/appV2/Worker/useWorkerGeoLocation";
import { parseISO } from "date-fns";

import { useGetOpenShiftsForDates } from "../../Shift/Open/useGetOpenShiftsForDates";
import { getShiftDiscoveryDefaultDateRange } from "../../utils/getShiftDiscoveryDefaultDateRange";
import { useShiftDiscoveryUserFiltersContext } from "../Filters/useUserFiltersContext";

interface UseUrgentShiftsDataProps {
  enabled?: boolean;
}

export function useUrgentShiftsData(props: UseUrgentShiftsDataProps = {}) {
  const { enabled = true } = props;

  const { dates: dateStrings, distance } = useShiftDiscoveryUserFiltersContext();
  const dates = dateStrings.map((dateString) => parseISO(dateString));

  const workerGeoLocation = useWorkerGeoLocation();

  return useGetOpenShiftsForDates({
    dates,
    dateRange: getShiftDiscoveryDefaultDateRange(),
    filter: {
      isUrgent: true,
      area: {
        latitude: workerGeoLocation?.latitude ?? 0,
        longitude: workerGeoLocation?.longitude ?? 0,
        radiusInMiles: distance,
      },
    },
    enabled: enabled && isDefined(workerGeoLocation),
  });
}

import { PageHeaderWrapper } from "@clipboard-health/ui-components";
import { Title } from "@clipboard-health/ui-react";
import { Stack } from "@mui/material";
import { RootPaths } from "@src/appV2/App/paths";
import { useQueryClient } from "@tanstack/react-query";
import pluralize from "pluralize";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import { IconButton } from "../../components/IconButton";
import { invalidateShifts } from "../../invalidateShifts";
import { OpenShiftListDataProvider } from "../../Shift/Open/useOpenShiftListDataContext";
import { VirtualShiftListContextProvider } from "../../Shift/Open/useVirtualShiftListContext/useVirtualShiftListContext";
import { useScrollToTopOnStatusTap } from "../../useScrollToTopOnStatusTap";
import { PullToRefresh } from "../ListView/PullToRefresh";
import { SHIFT_DISCOVERY_LIST_PATH, SHIFT_DISCOVERY_URGENT_PATH } from "../paths";
import {
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM,
  SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT,
} from "../SearchModeSwitcher";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { UrgentShiftsList } from "./List";
import { useHandleUrgentShiftsDeepLink } from "./useHandleUrgentShiftsDeepLink";
import { useUrgentShiftsData } from "./useUrgentShiftsData";

export function ShiftDiscoveryUrgentShiftsPage() {
  const queryClient = useQueryClient();
  const stackRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  const { openedFromDeepLink } = useHandleUrgentShiftsDeepLink();

  useScrollToTopOnStatusTap(stackRef);

  const { setBaseNavigationPathOverride } = useShiftModalsDataContext();

  /**
   * When this page is opened, we want to override the base navigation path
   * so that when shift modals are opened from here, they are rendered on top of this page
   */
  useEffect(() => {
    setBaseNavigationPathOverride(SHIFT_DISCOVERY_URGENT_PATH);
    return () => {
      setBaseNavigationPathOverride(undefined);
    };
  }, [setBaseNavigationPathOverride]);

  const {
    shifts: urgentShifts,
    isError,
    isLoading,
    isSuccess,
    workplacesMap,
    rateNegotiationsMap,
  } = useUrgentShiftsData();

  return (
    <Stack
      sx={(theme) => ({
        flex: 1,
        backgroundColor: theme.palette.background.tertiary,
        overflow: "hidden",
        position: "relative",
      })}
    >
      <OpenShiftListDataProvider
        openShifts={urgentShifts}
        workplacesMap={workplacesMap}
        rateNegotiationsMap={rateNegotiationsMap}
        isOpenShiftsLoading={isLoading}
        isOpenShiftsError={isError}
        isOpenShiftsSuccess={isSuccess}
      >
        <VirtualShiftListContextProvider shifts={urgentShifts} workplacesMap={workplacesMap}>
          <PullToRefresh
            scrollRef={stackRef}
            onRefresh={async () => {
              await invalidateShifts(queryClient);
            }}
          />

          <Stack
            ref={stackRef}
            data-testid="urgent-shifts-page-scroll-container"
            spacing={7}
            sx={(theme) => ({
              flex: 1,
              overflow: "auto",
              px: 5,
              pt: 6,
              pb: `calc(
          ${theme.spacing(6)} +
          ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_HEIGHT)} +
          ${theme.spacing(SHIFT_DISCOVERY_SEARCH_MODE_SWITCHER_SPACING_BOTTOM)}
        )`,
            })}
          >
            <PageHeaderWrapper variant="tertiary">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                position="relative"
                height={(theme) => theme.spacing(10)}
                px={3}
              >
                <Stack position="absolute" left={(theme) => theme.spacing(3)}>
                  <IconButton
                    iconType="arrow-left"
                    variant="outlined"
                    onClick={() => {
                      // If we come from a deep link, there is nowhere to go back to,
                      // so we need to push directly to list view
                      if (openedFromDeepLink || history.length === 1) {
                        history.push(`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`);
                        return;
                      }

                      history.goBack();
                    }}
                  />
                </Stack>
                <Title semibold component="h2" variant="h6">
                  {urgentShifts.length > 0 ? urgentShifts.length : ""} Urgent{" "}
                  {pluralize("Shift", urgentShifts.length)}
                </Title>
              </Stack>
            </PageHeaderWrapper>

            <UrgentShiftsList scrollRef={stackRef} />
          </Stack>
        </VirtualShiftListContextProvider>
      </OpenShiftListDataProvider>
    </Stack>
  );
}

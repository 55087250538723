import { Redirect, Route, Switch } from "react-router-dom";

import { RootPaths } from "../../App/paths";
import { ShiftDiscoverySearchMode } from "../../OpenShifts/ShiftAction/api/useClaimShift";
import { ShiftBlocksPage } from "../Shift/Block/Page";
import { OnCallOffersPage } from "../WorkerShifts/OnCallOffers/Page";
import { CommonModalRoutes } from "./CommonModalRoutes";
import { ShiftDiscoveryHomeHealthShiftsPage } from "./HomeHealthShifts/Page";
import { ShiftDiscoveryListViewPage } from "./ListView/Page";
import { ShiftDiscoveryMapViewPage } from "./MapView/Page";
import {
  SHIFT_DISCOVERY_BLOCKS_PATH,
  SHIFT_DISCOVERY_HOME_HEALTH_PATH,
  SHIFT_DISCOVERY_LIST_PATH,
  SHIFT_DISCOVERY_MAP_PATH,
  SHIFT_DISCOVERY_ON_CALL_PATH,
  SHIFT_DISCOVERY_PRIORITY_PATH,
  SHIFT_DISCOVERY_URGENT_PATH,
} from "./paths";
import { ShiftDiscoveryPriorityShiftsPage } from "./PriorityShifts/Page";
import { ShiftModalRoutes } from "./ShiftModalRoutes";
import { ShiftDiscoveryUrgentShiftsPage } from "./UrgentShifts/Page";
import { useInvalidateShiftsOnDeeplink } from "./useInvalidateShiftsOnDeeplink";
import { useShiftDiscoverySearchMode } from "./useShiftDiscoverySearchMode";
import { ShiftModalsDataProvider } from "./useShiftModalsDataContext";
import { WorkplaceModalsDataProvider } from "./useWorkplaceModalsDataContext";
import { WorkplaceModalRoutes } from "./WorkplaceModalRoutes";

export function ShiftDiscoveryRouter() {
  useInvalidateShiftsOnDeeplink();

  const searchMode = useShiftDiscoverySearchMode();

  const baseModalsNavigationPath =
    searchMode === ShiftDiscoverySearchMode.LIST
      ? `${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`
      : `${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_MAP_PATH}`;

  return (
    <WorkplaceModalsDataProvider baseNavigationPath={baseModalsNavigationPath}>
      <ShiftModalsDataProvider
        baseNavigationPath={baseModalsNavigationPath}
        searchMode={searchMode}
      >
        <WorkplaceModalRoutes />
        <ShiftModalRoutes />
        <CommonModalRoutes baseNavigationPath={baseModalsNavigationPath} />

        <Switch>
          <Route
            path={[
              `${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}/${SHIFT_DISCOVERY_ON_CALL_PATH}`,
              `${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_MAP_PATH}/${SHIFT_DISCOVERY_ON_CALL_PATH}`,
            ]}
            component={OnCallOffersPage}
          />
          <Route
            path={`${baseModalsNavigationPath}/${SHIFT_DISCOVERY_URGENT_PATH}`}
            component={ShiftDiscoveryUrgentShiftsPage}
          />
          <Route
            path={`${baseModalsNavigationPath}/${SHIFT_DISCOVERY_HOME_HEALTH_PATH}`}
            component={ShiftDiscoveryHomeHealthShiftsPage}
          />
          <Route
            path={`${baseModalsNavigationPath}/${SHIFT_DISCOVERY_PRIORITY_PATH}`}
            component={ShiftDiscoveryPriorityShiftsPage}
          />
          <Route
            path={`${baseModalsNavigationPath}/${SHIFT_DISCOVERY_BLOCKS_PATH}`}
            component={ShiftBlocksPage}
          />
          <Route
            path={`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`}
            component={ShiftDiscoveryListViewPage}
          />
          <Route
            path={`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_MAP_PATH}`}
            component={ShiftDiscoveryMapViewPage}
          />
          <Route
            render={() => <Redirect to={`${RootPaths.APP_V2_HOME}/${SHIFT_DISCOVERY_LIST_PATH}`} />}
          />
        </Switch>
      </ShiftModalsDataProvider>
    </WorkplaceModalsDataProvider>
  );
}

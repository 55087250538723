import { isDefined } from "@clipboard-health/util-ts";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { invalidateShifts } from "../invalidateShifts";
import { ModalRoute } from "../Navigation/ModalRoute";
import { ModalTransitionSwitch } from "../Navigation/ModalTransitionSwitch";
import { LegacyQuizFlowContainer } from "../Quiz/LegacyQuizFlowContainer";
import { InstantReviewShiftDocumentSubmittedBottomSheet } from "../Shift/Bookability/Documents/InstantReview/DocumentSubmittedBottomSheet";
import { InstantReviewHelloSignFormBottomSheet } from "../Shift/Bookability/Documents/InstantReview/HelloSignFormBottomSheet";
import { LocationPermissionsBottomSheet } from "../Shift/Bookability/LocationPermissionsBottomSheet";
import { ConfirmBookingBottomSheet } from "../Shift/BookingConfirmationBottomSheet/ConfirmBookingBottomSheet";
import { OnCallRegisterConfirmationBottomSheet } from "../Shift/BookingConfirmationBottomSheet/OnCallRegisterConfirmationBottomSheet";
import { ShiftBookedBottomSheet } from "../Shift/BookingConfirmationBottomSheet/ShiftBookedBottomSheet";
import { ShiftBottomSheet } from "../Shift/BottomSheet/Sheet";
import { BreakConfirmationModalRouteContainer } from "../Shift/BreakConfirmationBottomSheet/BreakConfirmationModalRouteContainer";
import { OnCallShiftOfferDetailsBottomSheet } from "../WorkerShifts/OnCallOffers/ShiftOfferDetails/BottomSheet";
import {
  SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH,
  SHIFT_DISCOVERY_ON_CALL_SHIFT_MODAL_PATH,
  SHIFT_DISCOVERY_ON_CALL_SHIFT_REGISTERED_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_CONFIRM_BOOKING_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_DOCUMENT_SUBMITTED_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_MODAL_PATH,
  SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH,
} from "./paths";
import { useShiftModalsDataContext } from "./useShiftModalsDataContext";
/**
 * ShiftModalRoutes handles all shift-related modals in the application.
 *
 * Key characteristics:
 * 1. Can be rendered independently or on top of workplace modals
 * 2. Uses baseNavigationPathOverride when rendered on top of workplace modals
 * 3. Shares common transition animations with other modals
 *
 * Common flows:
 * - Direct shift modal: /shift-discovery/list/shift/:id
 * - Nested shift modal: /shift-discovery/list/workplace/:id/open-shifts/shift/:id
 */
export function ShiftModalRoutes() {
  const {
    baseNavigationPath: defaultBaseNavigationPath,
    baseNavigationPathOverride,
    shift,
    bookabilityStatus,
  } = useShiftModalsDataContext();

  const isNestedModalContext = isDefined(baseNavigationPathOverride);
  const baseNavigationPath = isNestedModalContext
    ? `${defaultBaseNavigationPath}/${baseNavigationPathOverride}`
    : defaultBaseNavigationPath;

  const queryClient = useQueryClient();
  const location = useLocation();

  useEffect(() => {
    // Invalidate shifts cache when booking confirmation appears.
    // Previously, when this invalidation happened inside the modal component,
    // it would be cancelled during modal unmounting, causing UI inconsistencies.
    // If you closed the modal quick enough, list would not be updated, showing your booked shifts as open (but not bookable)
    if (
      isDefined(
        matchPath(
          location.pathname,
          `${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH}`
        )
      )
    ) {
      void invalidateShifts(queryClient);
    }
  }, [queryClient, location.pathname, baseNavigationPath]);

  return (
    <ModalTransitionSwitch>
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_MODAL_PATH}`}
        render={({ modalState }) => {
          return <ShiftBottomSheet modalState={modalState} />;
        }}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_ON_CALL_SHIFT_MODAL_PATH}`}
        render={({ modalState }) => {
          return <OnCallShiftOfferDetailsBottomSheet modalState={modalState} />;
        }}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BOOKED_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(shift)) {
            return null;
          }

          return <ShiftBookedBottomSheet shift={shift} modalState={modalState} />;
        }}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_CONFIRM_BOOKING_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(shift)) {
            return null;
          }

          return <ConfirmBookingBottomSheet shift={shift} modalState={modalState} />;
        }}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_ON_CALL_SHIFT_REGISTERED_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(shift)) {
            return null;
          }

          return <OnCallRegisterConfirmationBottomSheet shift={shift} modalState={modalState} />;
        }}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_BREAK_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(shift)) {
            return null;
          }

          return (
            <BreakConfirmationModalRouteContainer
              shiftId={shift.id}
              shiftOfferId={shift?.offer?.id}
              workplaceId={shift?.workplace.id}
              modalState={modalState}
            />
          );
        }}
      />

      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_ENABLE_LOCATION_PERMISSIONS_PATH}`}
        render={({ modalState }) => <LocationPermissionsBottomSheet modalState={modalState} />}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={isNestedModalContext}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_QUIZ_MODAL_PATH}`}
        render={({ modalState }) => {
          if (!isDefined(shift)) {
            return null;
          }

          return (
            <LegacyQuizFlowContainer
              modalState={modalState}
              shiftId={shift.id}
              shiftOfferId={shift.offer?.id}
              workplaceId={shift.workplace.id}
              workplaceName={shift.workplace.attributes.name}
            />
          );
        }}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={false}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_HELLO_SIGN_CONTAINER_MODAL_PATH}`}
        render={({ modalState }) => {
          return <InstantReviewHelloSignFormBottomSheet modalState={modalState} />;
        }}
      />
      <ModalRoute
        closeModalPath={baseNavigationPath}
        shouldReplacePathOnBack={false}
        path={`${baseNavigationPath}/${SHIFT_DISCOVERY_SHIFT_DOCUMENT_SUBMITTED_MODAL_PATH}`}
        render={({ modalState }) => {
          return (
            <InstantReviewShiftDocumentSubmittedBottomSheet
              modalState={modalState}
              bookabilityStatus={bookabilityStatus}
            />
          );
        }}
      />
    </ModalTransitionSwitch>
  );
}

import { isDefined } from "@clipboard-health/util-ts";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { SHIFT_DISCOVERY_SHIFT_MODAL_PATH, SHIFT_DISCOVERY_URGENT_PATH } from "../paths";
import { useShiftModalsDataContext } from "../useShiftModalsDataContext";
import { useUrgentShiftsData } from "./useUrgentShiftsData";

/**
 * If the page is opened from a deep link with a shiftId,
 * we want to navigate to the shift modal directly.
 */
function useNavigateToShiftFromDeepLink(deepLinkShiftId?: string) {
  const { baseNavigationPathOverride, navigateToModal } = useShiftModalsDataContext();

  const shouldOpenShiftFromDeepLink =
    isDefined(deepLinkShiftId) &&
    // Wait for the base navigation path to be updated
    baseNavigationPathOverride === SHIFT_DISCOVERY_URGENT_PATH;

  useEffect(() => {
    if (shouldOpenShiftFromDeepLink) {
      logEvent(APP_V2_USER_EVENTS.URGENT_SHIFTS_DEEPLINK_SHIFT_OPENED, {
        shiftId: deepLinkShiftId,
      });

      navigateToModal(SHIFT_DISCOVERY_SHIFT_MODAL_PATH, { shiftId: deepLinkShiftId });
    }
  }, [navigateToModal, shouldOpenShiftFromDeepLink, deepLinkShiftId]);
}

export function useHandleUrgentShiftsDeepLink() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // We might have a shiftId in the url if the page is opened from a deep link
  const deepLinkShiftId = searchParams.get("shiftId");
  const deepLinkActionType = searchParams.get("actionType");
  const openedFromDeepLink = isDefined(deepLinkShiftId) || isDefined(deepLinkActionType);

  useLogEffect(APP_V2_USER_EVENTS.URGENT_SHIFTS_DEEPLINK_OPENED, Object.fromEntries(searchParams), {
    enabled: openedFromDeepLink,
  });

  useNavigateToShiftFromDeepLink(deepLinkShiftId ?? undefined);

  const { shifts: urgentShifts, isSuccess: isUrgentShiftsSuccess } = useUrgentShiftsData();

  const isDeepLinkShiftInUrgentShifts =
    isUrgentShiftsSuccess &&
    isDefined(deepLinkShiftId) &&
    urgentShifts.some((shift) => shift.id === deepLinkShiftId);

  useLogEffect(
    APP_V2_USER_EVENTS.URGENT_SHIFTS_DEEPLINK_MISSING_SHIFT,
    {
      deepLinkShiftId,
      fetchedUrgentShiftIds: urgentShifts.map((shift) => shift.id),
    },
    {
      enabled: isDefined(deepLinkShiftId) && !isDeepLinkShiftInUrgentShifts,
    }
  );

  return {
    openedFromDeepLink,
  };
}
